import React from "react";

class ForgotPass extends React.Component {
  render() {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <h1>Forgot Password?</h1>
        <h3>No Worries</h3>
      </div>
    );
  }
}

export default ForgotPass;
